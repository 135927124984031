import "./styles.scss";
import navigation from "./components/navigation.html";
import hero from "./components/hero.html";
import features from "./components/features.html";
import footer from "./components/footer.html";

document.getElementById("app").innerHTML = `
${navigation}
${hero}
${features}
${footer}
`;

let imgs = document.getElementsByClassName("hero__browser-image");
let imgsLoadedCount = 0;

function loaded() {
  imgsLoadedCount++;

  if (imgsLoadedCount === 4) {
    // rendering the imgs again to trigger the class effect
    for (let img of imgs) {
      img.classList.add("hero__browser-image-loaded");
    }

    // show the credit
    let credits = document.getElementsByClassName("hero__browser-image-credit");
    for (let credit of credits) {
      credit.style.opacity = 1;
    }

    // Start the slideshow logic
    const slideshow = document.getElementById("slideshow");

    const fadeComplete = function () {
      slideshow.appendChild(arr[0]);
    };

    const arr = slideshow.getElementsByTagName("div");
    for (let i = 0; i < arr.length; i++) {
      arr[i].addEventListener("animationend", fadeComplete, false);
    }
  }
}

for (let img of imgs) {
  const currentImage = img.firstElementChild;
  if (currentImage.complete) {
    loaded();
  } else {
    currentImage.addEventListener("load", loaded);
    currentImage.addEventListener("error", () => null);
  }
}
